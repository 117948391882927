






















































import { Component, Vue, Inject } from 'vue-property-decorator';
import BoxPage from '../../../components/BoxPage.vue';
import ControlLabel from '../../../components/ControlLabel.vue';
import BoxContent from '../../../components/Box/BoxContent.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ControlError from '../../../components/ControlError.vue';
import config from '@/env';
import { UnsubscribingService } from '@/modules/sts/unsubscribing/unsubscribing.service';
import { SubscribingStatus } from '@/modules/sts/unsubscribing/subscribing-status.model';
import { SsrCtx, WithAsyncData } from '@/core/vue.types';
import { universalRedirect } from '../../../fns/router';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { pushWithLangAndPlatform } from '@/themes/v1/fns/router';

@Component({
  components: {
    BoxPage,
    ControlLabel,
    BoxContent,
    ValidationObserver,
    ValidationProvider,
    ControlError,
  },
})
export default class Unsubscribe extends Vue implements WithAsyncData {
  @Inject()
  unsubscribingService!: UnsubscribingService;
  comment = '';
  processing = false;
  ready = false;

  get valid(): boolean {
    return this.comment.trim().length > 3;
  }

  async asyncData(ctx: SsrCtx) {
    const unsubscribingService = resolveFromCtx<UnsubscribingService>(
      'unsubscribingService',
      ctx
    );
    const status = await unsubscribingService.getStatus(
      ctx.route.params.ticket
    );
    if (!status) {
      universalRedirect(ctx, `unsubscribe/${SubscribingStatus.NotFound}`);
      return;
    }
    if (status != SubscribingStatus.Subscribed) {
      universalRedirect(ctx, `unsubscribe/${status}`);
      return;
    }
    return { ready: true };
  }

  async submit() {
    this.processing = true;
    try {
      const command = {
        game: config.ticketsGame,
        ticketId: this.$route.params.ticket,
        message: this.comment,
        language: this.$i18n.locale,
      };
      const success = await this.unsubscribingService.unsubscribe(command);
      if (success) {
        await pushWithLangAndPlatform(this.$router, `unsubscribe/success`);
      } else {
        await pushWithLangAndPlatform(this.$router, `unsubscribe/failed`);
      }
    } finally {
      this.processing = false;
    }
  }
}
